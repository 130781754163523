@import 'codemirror/lib/codemirror.css';

/* Most of this is taken from the Go playground site. It's a parody so I'm pretty sure this constitutes fair use */

.OK {
  font-style:italic;
}

.Select {
  font-size: 0.875rem;
  border: 0.0625rem solid #3764c4;
}

.Button {
  height: 30px;
  border: 1px solid #3764c4;
  font-size: 16px;
  font-family: sans-serif;
  background: #3764c4;
  color: white;
  position: static;
  top: 1px;
  border-radius: 5px;
  -webkit-appearance: none;
  font-weight:600;
}

.Head {
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 5px;
  font-size: 20px;
  font-family: sans-serif;
}

.Banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  background-color: #b4d3eb;
}

.Banner > * {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  height: 30px;
}


.Editor > *{
  margin-top: 50px;
}

.Output {
  font-family: Menlo, monospace;
  font-size: 11pt;
  padding:10px;
  white-space: pre-line;
}

.RunComplete {
  color: grey;
}

.CodeMirror {
  height: 670px;
  font-family: Menlo, monospace;
  font-size: 14pt;
  font-weight: 500;
  background:#fff7f7;
}

.ShareInput {
  width: 280px;
  font-size: 16px;
  border: 1px solid #ccc;
  background: #eee;
  color: black;
  overflow-x: scroll;
  white-space: nowrap;
  padding-top: 4px;
}

.cm-s-mdn-like.CodeMirror {
  background-image: none;
}

.cm-s-mdn-like .CodeMirror-gutters {
  border-left: 6px;
}

.cm-s-mdn-like.CodeMirror {
  background: #fff7f7;
}


.Modal-outer {
  display: table;
  position: absolute;
  z-index:10;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

}

.Modal-middle {
  display: table-cell;
  vertical-align: middle;
}

.Modal-inner {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 500px;
  background-color:white;
  border: 3px solid #ff0000;
  padding: 20px;
  animation: blink-border 1s step-start infinite;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.Modal-heading {
  color: red;
}

.Modal-heading{
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blink-border {
  0% {
    border: 3px solid #ff0000;
  }
  50% {
    border: 3px solid black;
  }
}

.italic {
  font-style:italic
}


.enable-button  {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.enable-button.disabled {
  color: grey;
  cursor:default;
}

.Modal-buttons >*+*{
  margin-left:10px;
}
